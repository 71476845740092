import {Injectable} from '@angular/core';
import {BlRequestService} from '../../support/blRequest.service';
import {Observable} from "rxjs";

@Injectable()
export class BlSampleTypesRequestsService {

    constructor(private blRequestService: BlRequestService) {
    }

    public listBySampleSource(sampleSource): Observable<any> {
        const path = '/sample-types/' + sampleSource;
        return this.blRequestService.get(path);
    }

    public searchBy(query: string): Observable<any> {
        const path = '/sample-types/search?query=' + query;
        return this.blRequestService.get(path);
    }

}
